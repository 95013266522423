import { createApp } from 'vue'
import axios from 'axios';
import App from './App.vue'
import moment from 'moment-timezone';
import vueSmoothScroll from 'vue3-smooth-scroll';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const app = createApp(App)

// Configure plugins before mounting
app.config.devtools = true;
app.use(vueSmoothScroll);

// Mount the app
app.mount("#app");

// Set global properties
moment.locale("pl");
app.config.globalProperties.$filters = {
  formatDate(value){
    if (value) {
      return moment(String(value)).utc().format('DD MMMM - HH:mm')
    }
  }
};