<template>
  <div class="content">
    <b-row class="title">
      <b-container>
        <h2>SKONTAKTUJ SIĘ</h2>
        <p class="description">
          Jeśli masz jakiekolwiek pytania, zapraszamy do kontaktu.
        </p>
      </b-container>
    </b-row>
    <b-row class="form-and-information">
      <b-col class="space-keeper"/>
      <b-col class="information">
        <p>noifajnie.teatrimpro@gmail.com</p>
        <p>(+48) 791 965 456</p>
        <p>Leszek Ludwicki</p>
      </b-col>
      <b-col class="form">
        <form v-on:submit="onsubmit" action="#" method="post">
          <input type="text" title="Imię" id="name" v-model="fullName" aria-label="Imię" placeholder="Imię"/><br/>
          <input type="text" title="Email" id="email" aria-label="e-mail" v-model="email" placeholder="E-mail"/><br/>
          <textarea title="Treść" id="text" aria-label="Treść" v-model="message" placeholder="Treść"/>
          <input type="submit" v-on:submit="onsubmit" value="Wyślij"/>
        </form>
      </b-col>
      <b-col md="none" lg="2" class="space-keeper"/>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactForm",
  data: () => {
    return {
      email: '',
      fullName: '',
      message: ''
    }
  },
  methods: {
    onsubmit: async function (event) {
      event.preventDefault();
      await axios.post(
          process.env.VUE_APP_BACKEND_URL + `/api/contact`,
          {
            email: this.email,
            name: this.fullName,
            text: this.message
          }
      );
    },
  }
}
</script>

<style scoped>
.description {
  font-size: small;
}

.content {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  height: 10vh;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 0;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  top: 0;
}

textarea, select {
  width: 100%;
  height: 300px;
  padding: 12px 20px;
  margin: 4px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: palegreen;
  color: darkslategray;
  padding: 14px 20px;
  margin: 8px 0 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

input[type=submit]:hover {
  background-color: green;
  color: whitesmoke;
}

input[type=submit]:active {
  background-color: darkslategrey;
}

.form {
  border-radius: 5px;
  padding: 0 10px;
  background-color: whitesmoke;
  width: 100%;
}

.information {
  padding-top: 25px;
  background-color: palegreen;
  width: 100%;
}

.form-and-information {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

#name {
  margin-top: 5px;
}

@media only screen and (min-width: 844px) {
  .information {
    padding-top: 200px;
    font-size: xx-large;
  }


  .title {
    height: 15vh;
  }
}

@media (min-width: 1024px) {


  .description {
    font-size: medium;
  }
}

@media (max-width: 768px) {
  .form {
    padding-top: 20px;
  }
}

@media (max-width: 844px) {
  /* Adjust the max-width value to target the iPhone 12 Pro size */
  .form-and-information {
    flex-direction: column;
    align-items: center;
  }

  .information {
    order: 1; /* Move .information to the top */
  }

  .form {
    order: 2; /* Move .form to the bottom */
  }
}

a {
  text-decoration: none;
  color: darkslategrey;
}

table {
  margin-left: auto;
  margin-right: auto;
}

td {
  padding: 5px;
}
</style>
