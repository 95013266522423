<template>
  <div class="image-gallery">
    <div v-if="images.length" class="image-wrapper">
      <transition name="slide-fade" mode="out-in">

        <div :key="currentIndex" class="content-wrapper">
          <img :key="currentIndex"
               :src="imageUrl(images[currentIndex])"
               :alt="images[currentIndex].name"
               @touchstart="handleStart($event, 'touch')"
               @touchmove="handleMove($event, 'touch')"
               @touchend="touchEndHandler"
               @mousedown="handleStart($event, 'mouse')"
               @mousemove="handleMove($event, 'mouse')"
               @mouseup="handleEnd"
               @mouseleave="mouseLeaveHandler"
               @mouseenter="hoverStart"
          />
          <div class="description">
            <p class="title">{{ images[currentIndex].name }}
            <br/>
            <i>{{ images[currentIndex].date }}</i>
            </p>
            <p>{{ images[currentIndex].text }}</p>
          </div>
        </div>
      </transition>
    </div>
    <p v-else>No images available.</p>
    <div class="dots">
      <span
        v-for="(image, index) in images"
        :key="index"
        :class="{ 'active-dot': currentIndex === index }"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      distance: 0,
      swipeDirection: null,
      isSwiping: false,
    };
  },
  mounted() {
    this.startAutoplay();
  },
  beforeDestroy() {
    this.stopAutoplay();
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
      this.resetAutoplay();
    },
    imageUrl(item) {
      return `${process.env.VUE_APP_BACKEND_URL}${item.photo}`;
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0; // loop back to the start
      }
      this.resetAutoplay();
      this.swipeDirection = 'left';
    },

    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1; // loop back to the end
      }
      this.resetAutoplay();
      this.swipeDirection = 'right';
    },

    hoverStart() {
      this.stopAutoplay();
    },

    hoverEnd() {
      this.startAutoplay();
    },

    touchBegin() {
      this.stopAutoplay();
    },

    touchEndHandler() {
      this.startAutoplay();
      this.handleEnd();  // Call handleEnd after resuming autoplay to process swipe
    },
    handleStart(event, source) {
      if (source === 'mouse') {
        this.startX = event.clientX;
        this.isSwiping = true; // track if mouse is down for swiping
      } else if (source === 'touch') {
        this.startX = event.touches[0].clientX;
      }
    },

    handleMove(event, source) {
      if (source === 'mouse' && !this.isSwiping) {
        return; // do not handle if mouse is not down for swiping
      }

      let currentX = source === 'mouse' ? event.clientX : event.touches[0].clientX;
      this.distance = this.startX - currentX;
    },

    handleEnd() {
      const threshold = 100;
      if (this.distance > threshold) {
        this.swipeDirection = 'left';
        this.nextImage();
      } else if (this.distance < -threshold) {
        this.swipeDirection = 'right';
        this.prevImage();
      }

      // Reset values
      this.distance = 0;
      this.isSwiping = false;  // reset the mouse swiping state
      this.swipeDirection = null;
    },

    startAutoplay() {
      // Clear existing interval if any, to reset the timer
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval);
      }
      this.autoplayInterval = setInterval(this.nextImage, 10000); // Change every 10 seconds
    },

    resetAutoplay() {
      this.stopAutoplay();
      this.startAutoplay();
    },

    stopAutoplay() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    },

    mouseLeaveHandler() {
      this.handleEnd();
      this.hoverEnd();
    }
  },
};
</script>

<style scoped>

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dots span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dots span.active-dot {
  background-color: #333;
}

@media (max-width: 480px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-wrapper img {
    max-width: 100%;
    margin: auto;
    height: auto;
  }

  .description {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 100px;
}

.image-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 50%;
  margin-right: 20px;
  border-radius: 8px;
}

.description {
  flex: 1; /* Ensure that this div takes up the rest of the space */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center the content vertically */
}

p.title {
  color: green;
  margin: 0 0 10px 0; /* Add some space between title and description */
}

p {
  margin: 0;
}

.content-wrapper {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Slide to the left transition */
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-left-enter-from, .slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to, .slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

/* Slide to the right transition */
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-right-enter-from, .slide-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-to, .slide-right-leave-from {
  transform: translateX(0);
  opacity: 1;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

</style>
