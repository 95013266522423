<template>
  <bs-container class="content">
    <h2>O nas</h2>
    <bs-container>
      No i Fajnie - Teatr Impro to krzyżówka aktorów, tancerzy, śpiewaków i muzyków z wieloletnim doświadczeniem
      scenicznym.<br/>
      Połączyła ich masa przypadków i wspólna pasja. Ich drogi się przecięły, zaczęli razem działać, aż w końcu
      padło: <br/>
      - Robimy to? <br/>
      - Robimy!<br/>
      Wszyscy spojrzeli po sobie i razem zakrzyknęli:<br/>
      - No i Fajnie!
    </bs-container>
    <b-container class="gallery" fluid>
      <div v-if="!this.loading">
        <div v-if="this.$props.innerWidth > 1024">
          <slider :images="this.data" :side="400" :controlsVisible="true"></slider>
        </div>
        <div v-else>
          <slider :images="this.data" :side="200" :controlsVisible="false"></slider>
        </div>
      </div>
    </b-container>
  </bs-container>
</template>

<script>
import axios from 'axios';
import Slider from './Slider/Slider';

export default {
  name: "TeamGallery",
  props: {
    innerWidth: Number,
    controlsVisible: Boolean
  },
  components: {
    Slider
  },
  data() {
    return {
      data: [],
      loading: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/about-us');
      this.data = response.data;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.gallery {
  margin-top: 30px;
  padding-top: 50px;
  margin-left: 20px;
}


.content {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .gallery {
    padding: 0;
  }

  .content {
    font-size: medium;
  }
}

@media (max-width: 768px) {
  .content {
    font-size: small;
  }
}

</style>
