<template>
  <div class="image-gallery">
    <div v-if="images.length" class="image-wrapper">
      <transition name="slide-fade" mode="out-in">
        <img class="polaroid"
             :key="currentIndex"
             :src="imageUrl(images[currentIndex])"
             :alt="images[currentIndex].name"
             @touchstart="handleStart($event, 'touch')"
             @touchmove="handleMove($event, 'touch')"
             @touchend="touchEndHandler"
             @mousedown="handleStart($event, 'mouse')"
             @mousemove="handleMove($event, 'mouse')"
             @mouseup="handleEnd"
             @mouseleave="mouseLeaveHandler"
             @mouseenter="hoverStart"
        />
      </transition>
      <p>{{ images[currentIndex].name }}</p>
      <p class="text">{{ images[currentIndex].text }}</p>
      <div class="dots">
        <span
          v-for="(image, index) in images"
          :key="index"
          :class="{ 'active-dot': currentIndex === index }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>
    <p v-else>No images available.</p>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      distance: 0,
      swipeDirection: null,
      isSwiping: false,
    };
  },
  mounted() {
    this.startAutoplay();
  },
  beforeDestroy() {
    this.stopAutoplay();
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
      this.resetAutoplay();
    },
    imageUrl(item) {
      return `${process.env.VUE_APP_BACKEND_URL}${item.photo}`;
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0; // loop back to the start
      }
      this.resetAutoplay();
      this.swipeDirection = 'left';
    },

    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1; // loop back to the end
      }
      this.resetAutoplay();
      this.swipeDirection = 'right';
    },

    hoverStart() {
      this.stopAutoplay();
    },

    hoverEnd() {
      this.startAutoplay();
    },

    touchBegin() {
      this.stopAutoplay();
    },

    touchEndHandler() {
      this.startAutoplay();
      this.handleEnd();  // Call handleEnd after resuming autoplay to process swipe
    },
    handleStart(event, source) {
      if (source === 'mouse') {
        this.startX = event.clientX;
        this.isSwiping = true; // track if mouse is down for swiping
      } else if (source === 'touch') {
        this.startX = event.touches[0].clientX;
      }
    },

    handleMove(event, source) {
      if (source === 'mouse' && !this.isSwiping) {
        return; // do not handle if mouse is not down for swiping
      }

      let currentX = source === 'mouse' ? event.clientX : event.touches[0].clientX;
      this.distance = this.startX - currentX;
    },

    handleEnd() {
      const threshold = 100;
      if (this.distance > threshold) {
        this.swipeDirection = 'left';
        this.nextImage();
      } else if (this.distance < -threshold) {
        this.swipeDirection = 'right';
        this.prevImage();
      }

      // Reset values
      this.distance = 0;
      this.isSwiping = false;  // reset the mouse swiping state
      this.swipeDirection = null;
    },

    startAutoplay() {
      // Clear existing interval if any, to reset the timer
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval);
      }
      this.autoplayInterval = setInterval(this.nextImage, 5000); // Change every 5 seconds
    },

    resetAutoplay() {
      this.stopAutoplay();
      this.startAutoplay();
    },

    stopAutoplay() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    },

    mouseLeaveHandler() {
      this.handleEnd();
      this.hoverEnd();
    }
  },
};
</script>

<style scoped>

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dots span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dots span.active-dot {
  background-color: #333;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.image-wrapper {
  position: relative;
  text-align: center;
}

img {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Slide to the left transition */
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-left-enter-from, .slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to, .slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

/* Slide to the right transition */
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-right-enter-from, .slide-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-right-enter-to, .slide-right-leave-from {
  transform: translateX(0);
  opacity: 1;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 200px;
  width: 80%;
}

@media only screen and (min-width: 425px) {
  img {
    max-width: 300px;
  }

  .text {
    margin: auto;
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  img {
    max-width: 400px;
  }
}
</style>
