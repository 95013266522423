<template>
  <div class="content">
    <h2>WYDARZENIA</h2>
    <div class="contents">
      <b-container class="wall">
        <b-row v-for="event in data" :key="event.id" class="content-card">
          <b-row>
            <b-col class="heading">
              <b-row>
                <b-container>
                  <div class="photo">
                    <img :src="imageUrl(event)" :alt="event.title" :class="[ isDateBeforeToday(event.date) ? 'greyout' : '']"/>
                  </div>

                  <div class="title">
                    {{ event.title }}
                  </div>
                  <div class="date">
                    {{ $filters.formatDate(event.date) }}
                  </div>
                  <div class="location">
                    {{ event.location_name }} - {{ event.location }}
                  </div>
                </b-container>
              </b-row>
              <b-row>
                <div class="price">
                  <div v-if="isDateBeforeToday(event.date)">
                    WYDARZENIE JUŻ SIĘ ODBYŁO
                  </div>
                  <a v-else-if="event.ticket_url" :href="event.ticket_url">
                    KUP BILET
                  </a>
                  <div class="tickets" v-else>
                    BILETY <br/><i>do kupienia <br/>przed występem</i>
                  </div>
                </div>
                <div class="more">
                  <a :href="event.fb_url"><i class="fab fa-2x fa-facebook-square"></i></a>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-row>
      </b-container>
      <div class="navigation-arrows">
        <a class="fa-solid fa-2x fa-square-caret-left arrow next" @click="getEvents('previous')"></a>
        <anoi class="fa-solid fa-2x fa-square-caret-right arrow previous" @click="getEvents('next')"></anoi>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EventsGrid',
  data() {
    return {
      data: [],
      url: process.env.VUE_APP_BACKEND_URL + `/api/events`,
      directions: {
      }
    }
  },
  mounted() {
    this.today = new Date();
  },
  async created() {
    let response = await axios.get(this.url);
    this.data = response.data.results;
    this.directions["next"] = response.data.next;
    this.directions["previous"] = response.data.previous;
  },
  methods: {
    imageUrl(event) {
      return `${process.env.VUE_APP_BACKEND_URL}${event.photo}`;
    },
    async getEvents(direction) {
      if (this.directions[direction] !== null) {
        let response = await axios.get(this.url + this.directions[direction]);
        this.data = response.data.results;
        this.directions["next"] = response.data.next;
        this.directions["previous"] = response.data.previous;
      }
    },
    isDateBeforeToday(date) {
      let new_date = new Date(date);
      return this.today > new_date;
    }
  }
}
</script>

<style scoped>
.arrow {
  margin-left: 10px;
  margin-right: 10px;
}

.fa-facebook-square:hover, .arrow:hover {
  color: darkgreen;
  transition: 0.3s;
}
.arrow:active {
  color: green;
}

.greyout {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: 0.3s;
}

b-col:first-child img {
  aspect-ratio: 16 / 9;
  border-radius: 20px;
}

.title:hover {
  color: green;
  font-weight: bold;
  transition: 0.3s;
}

.content-card {
  width: auto;
  margin-bottom: 30px;
}

.heading {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: lightgrey;
  position: relative;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  padding: 2px;
}

.date, .more {
  font-size: 10px;
}

.tickets {
  font-size: 8px;
}

.price {
  margin-bottom: 10px;
  font-size: 12px;
}

.location {
  font-size: 8px;
  margin-bottom: 50px;
}

a {
  padding: 2px;
}

img {
  margin-top: 5px;
  max-width: 90%;
  border: lightgrey solid 10px;
  border-radius: 20px;
}

h2 {
  margin-bottom: 10px;
}

.more a {
  text-decoration: none;
  bottom: 15px;
  position: absolute;
  left: 20px;
  font-size: large;
  color: darkslategrey;
}

.price {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  text-align: right;
}

.price a {
  font-size: small;
  text-decoration: none;
  padding: 8px;
  border-radius: 15px;
  border: 0;
  background-color: whitesmoke;
  color: darkslategrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.price a:hover {
  background-color: green;
  transition: 0.3s;
  color: whitesmoke;
}

.more a:active {
  color: green;
}

.price a:active {
  background-color: lightgreen;
}

@media only screen and (min-width: 480px) {
  img {
    max-width: 480px;
    width: 80%;
  }

  .content {
    margin: auto;
    max-width: 100vw;
    width: 100%;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    position: absolute;
    flex-direction: column;
    align-items: center;
  }

  .content-card {
    width: 30%;
    margin-bottom: 20px;
  }
  .b-row {
    margin: 0;
    padding: 5px;
  }

  .heading {
    padding: 0;
    width: 50%;
  }

  .photo {
    margin: 0;
  }

  .date {
    padding-top: 1px;
  }

  .location {
    padding: 1px;
  }

  .price {
    bottom: 10px;
  }

  .price a {
    padding: 10px;
    border-radius: 15px;
  }
}

@media (min-width: 1024px) {
  * {
    border: red 3px;
  }

  b-col {
    height: 100%;
    width: 100vh;
  }

  .heading {
    width: 100%;
  }

  b-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .content-card {
    width: 40%;
    margin-bottom: 20px;
  }

  .title {
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .short {
    font-size: medium;
    width: 80%;
  }

  .date,
  .location {
    margin-bottom: 0;
  }

  .more {
    margin-left: auto;
    margin-top: 10px;
  }

  .content {
    max-width: 100vw;
    width: 100%;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    position: absolute;
    flex-direction: column;
    align-items: center;
  }

  .date {
    font-weight: bold;
    font-size: small;
    text-justify: auto;
    color: darkgreen;
  }

  .location {
    font-size: medium;
  }

}

</style>
