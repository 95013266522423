<template>
  <b-row class="content">
    <b-col md="4" lg="6">
      <a href="http://facebook.com/NoiFajnie.TeatrImpro" target="_blank">
        <i class="fab fa-2x fa-facebook-square"></i>
        <div class="hide-small">NoiFajnie.TeatrImpro</div>
      </a>
    </b-col>
    <b-col md="4" lg="6">
      <a href="http://instagram.com/noifajnie_teatrimpro" target="_blank">
        <i class="fab fa-2x fa-instagram"></i>
        <div class="hide-small">noifajnie_teatrimpro</div>
      </a>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "FootbarComponent"
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: darkslategrey;
}

a:hover {
  text-decoration: none;
  color: green;
}

a:active {
  text-decoration: none;
  color: darkgreen;
}

b-row {
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

b-col {
  flex: auto;
}

.content {
  color: darkslategrey;
  background-color: whitesmoke;
  place-self: center;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  justify-content: center;
  width: 100%;
  display: inline-flex;
  max-width: 100vw;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  width: 100%;
}

i {
  text-align: center;
}


@media (max-width: 425px) {
  .hide-small {
    font-size: 8pt;
  }
}

</style>
