<template>
  <div class="content">
    <div id="offer">
      <h2>OFERTA DLA CIEBIE</h2>
      <p class="description-text">
        Impro to bardzo elastyczne narzędzie, możemy przyjechać do Ciebie z gotowym występem,
        możemy przyjechać na Twój event i zrobić show albo pomóc w jego oprawie.
        Wchodzimy również w długofalowe współprace firmowe.
        Zapraszamy do zapoznania się z ofertami w formie PDF.
      </p>
      <b-row id="offers">
        <b-col>
          <a href="pdf/OFERTA-występu.pdf">
            <i class="far fa-lg fa-file-pdf"></i>&nbsp;&nbsp;Występ
          </a>
        </b-col>
        <b-col>
          <a href="pdf/OFERTA-eventowa.pdf">
            <i class="far fa-lg fa-file-pdf"></i>&nbsp;&nbsp;Event
          </a>
        </b-col>
        <b-col>
          <a href="pdf/Oferta-dla-Firm.pdf">
            <i class="far fa-lg fa-file-pdf"></i>&nbsp;&nbsp;Dla firm
          </a>
        </b-col>
      </b-row>
    </div>
    <div id="opinions-gallery">
      <div v-if="!this.loading">
        <div v-if="this.$props.innerWidth > 1024">
          <Slider :images="this.data" :width="800" :height="533" :controlsVisible="true"/>
        </div>
        <div v-else>
          <slider :images="this.data" :side="200" :controlsVisible="false"></slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from './Slider/Slider';
import axios from "axios";

export default {
  name: "OpinionsGallery",
  props: {
    innerWidth: Number
  },
  components: {
    Slider
  },
  data() {
    return {
      data: [],
      loading: true
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/opinions');
      this.data = response.data;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: darkslategrey;
}

a:hover {
  text-decoration: none;
  color: green;
}

a:active {
  text-decoration: none;
  color: darkgreen;
}

p {
  font-size: large;
  text-align: justify;
  text-justify: newspaper;
  width: 75%;
}

#offers {
  width: 100%;
  color: green;
}

#opinions-gallery {
  width: 100%;
  margin-top: 20px;
}

.content {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
}

.column {
  float: left;
  width: 33%;
}

@media (max-width: 1024px) {
  .content {
    font-size: small;
  }

  p {
    font-size: small;
    width: 90%;
  }
}

</style>
