<template>
    <div id="menu-content">
        <div class="full">
            <a href="#landing" v-smooth-scroll>
                <img class="menu-logo" src="../../../public/logo.png" alt="logo" aria-label="no i fajnie"/>
            </a>
            <div id="menu-text">
                <a href="#events" v-smooth-scroll="{offset: -100}">WYSTĘPY</a>
                <a href="#about-us" v-smooth-scroll="{offset: -100}">ZESPÓŁ</a>
                <a href="#opinions" v-smooth-scroll="{offset: -100}">OFERTA</a>
                <a href="#workshop" v-smooth-scroll="{offset: -100}">WARSZTATY</a>
                <div class="right">
                    <a :href="newsletter" target="_blank">NEWSLETTER</a>
                    <a href="#contact" v-smooth-scroll="{offset: -100}">KONTAKT</a>
                    <a href="http://facebook.com/NoiFajnie.TeatrImpro" target="_blank">
                        <i class="fab fa-lg fa-facebook-square"></i>
                    </a>
                    <a href="http://instagram.com/noifajnie_teatrimpro" target="_blank">
                        <i class="fab fa-lg fa-instagram"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="burger">
            <Slide>
                <a href="#landing" v-smooth-scroll>
                    <img class="menu-logo" src="../../../public/logo.png" alt="logo" aria-label="no i fajnie"/>
                </a>
                <a href="#events" v-smooth-scroll>WYSTĘPY</a>
                <a href="#about-us" v-smooth-scroll>ZESPÓŁ</a>
                <a href="#opinions" v-smooth-scroll>OFERTA</a>
                <a href="#workshop" v-smooth-scroll>WARSZTATY</a>
                <a :href="newsletter" target="_blank">NEWSLETTER</a>
                <a href="#contact" v-smooth-scroll>KONTAKT</a>
                <a href="http://facebook.com/NoiFajnie.TeatrImpro" target="_blank">
                    <i class="fab fa-lg fa-facebook-square">&nbsp;FACEBOOK</i>
                </a>
                <a href="http://instagram.com/noifajnie_teatrimpro" target="_blank">
                    <i class="fab fa-lg fa-instagram">&nbsp;INSTAGRAM</i>
                </a>
            </Slide>
        </div>
    </div>
</template>

<script>
  import {Slide} from "vue3-burger-menu";

  export default {
    name: "MenuBar",
    components: {
      Slide
    },
    computed: {
        newsletter() {
            return process.env.VUE_APP_BACKEND_URL + '/newsletter/';
        }
    }
  }
</script>

<style scoped>
    #menu-content {
        z-index: 1;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 1400px;
    }

    #menu-text a {
        padding: 20px 0 20px 20px;
        border: 20px;
        color: azure;
        text-decoration: none;
        height: 100%;
    }

    #menu-text a:hover {
        color: limegreen;
    }

    .menu-logo {
        float: left;
    }

    #menu-text {
        background-color: black;
        width: auto;
        margin-left: 45px;
    }

    @media (max-width: 1024px) {
        .full {
            display: none;
        }

        .burger a {
            color: azure;
            text-decoration: none;
        }

        #menu-text a:hover, a:active {
            color: limegreen;
        }

        .menu-logo {
            height: 100px;
            width: 100px;
        }

        #menu-content {
            height: 50px;
        }

        #menu-text {
            padding-top: 10px;
            font-size: 10px;
        }
    }

    @media (min-width: 1025px) {
        .burger {
            display: none;
        }

        .menu-logo {
            height: 150px;
            width: 150px;
        }

        #menu-text {
            margin-top: 0;
            background-color: black;
            width: auto;
            font-size: 20px;
            border-radius: 60px;
            padding-top: 25px;
            padding-bottom: 20px;
        }
    }
</style>
