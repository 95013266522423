<template>
  <div class="content">
    <h2>WARSZTATY</h2>
    <b-row>
      <p>
        Prowadzimy zarówno warsztaty cykliczne, tematyczne bądź weekendowe
        intensivy stricte z improwizacji scenicznej
        oraz warsztaty z umiejętności miękkich z wykorzystaniem narzędzi improwizacji.
      </p>
    </b-row>
    <b-row class="big">
      <b-col class="companies">
        <h2 class="title">
          <a href="pdf/OFERTA-warsztatowa.pdf" target="_parent">
            <i class="far fa-lg fa-file-pdf"></i> Dla firm i instytucji</a>
        </h2>
        <ul>
          <li>Icebraker</li>
          <li>Teamwork</li>
          <li>Storytelling</li>
          <li>Kreatywność</li>
          <li>Spontaniczność</li>
          <li>Wystąpienia publiczne</li>
        </ul>
      </b-col>
      <b-col class="groups">
        <h2 class="title">Dla grup impro</h2>
        <ul>
          <li>Platforma metodą CROW i autorską AEIOY</li>
          <li>Fail Happy, czyli jak pomyłki traktować jako prezenty</li>
          <li>Go deeper, czyli budowanie mięsistych relacji i krwistych postaci</li>
          <li>Warsztat z krótkich form i rozkładanie ich na czynniki pierwsze</li>
          <li>Większe zaufanie w grupie</li>
          <li>Warsztaty indywidualne dla grup</li>
          <li>Coaching grupy</li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="small">
      <b-col class="list">
        <b-row>
          <h2 class="title">
            <a href="pdf/OFERTA-warsztatowa.pdf" target="_parent">
              <i class="far fa-lg fa-file-pdf"></i>
              Dla firm i instytucji</a></h2>
        </b-row>
        <b-row>
          <b-col>
            <ul>
              <li>Icebraker</li>
              <li>Teamwork</li>
              <li>Wystąpienia publiczne</li>
            </ul>
          </b-col>
          <b-col>
            <ul>
              <li>Storytelling</li>
              <li>Kreatywność</li>
              <li>Spontaniczność</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="list">
          <b-row>
            <h2 class="title">Dla grup impro</h2>
          </b-row>
          <b-row>
            <ul>
              <li>Platforma metodą CROW i autorską AEIOY</li>
              <li>Fail Happy, czyli jak pomyłki traktować jako prezenty</li>
              <li>Go deeper, czyli budowanie mięsistych relacji i krwistych postaci</li>
              <li>Warsztat z krótkich form i rozkładanie ich na czynniki pierwsze</li>
              <li>Większe zaufanie w grupie</li>
              <li>Warsztaty indywidualne dla grup</li>
              <li>Coaching grupy</li>
            </ul>
          </b-row>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "WorkshopsComponent"
}
</script>

<style scoped>

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small {
  display: none;
}

.big {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

a {
  text-decoration: none;
  color: darkslategrey;
}

a:hover {
  text-decoration: none;
  color: green;
}

a:active {
  text-decoration: none;
  color: darkgreen;
}

.list {
  place-self: center;
  text-align: center;
  justify-content: center;
}

.title {
  color: darkslategrey;
  padding-top: 20px;
}

ul {
  list-style-type: circle;
  text-align: left;
  text-align: -moz-left;
  text-align: -webkit-left;
  font-size: small;
  padding-top: 20px;
}

li, p {
  padding: 10px;
  font-size: large;
}

button {
  display: none;
}

@media (max-width: 1024px) {
  .content {
    font-size: small;
  }

  p {
    width: 90%;
    margin: auto;
  }

  .title {
    text-align: center;
    font-size: medium;
    padding: 0;
    margin: auto;
  }

  ul {
    padding-top: 5px;
  }

  li, p {
    font-size: small;
    text-align: justify;
    text-justify: newspaper;
    padding: 0;
  }

  .big {
    display: none;
  }

  .small {
    display: unset;
  }
}
.companies, .groups {
  margin-left: auto;
  margin-right: auto;
  width: 50%; /* Or any suitable percentage */
}
</style>
