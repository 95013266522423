<template>
  <div id="app">
    <div id="menu">
      <MenuBar/>
    </div>
    <div class="grid-wrapper">
      <b-row id="landing">
        <div id="landing-background">
          <b-container fluid>
            <h1 id="landing-text">
              Improwizacje to sztuka tworzenia komedii na żywo,<br/>
              bez scenariusza - od ludzi dla ludzi.<br/>
              Całkowicie improwizowane spektakle nabierają niepowtarzalnego<br/>
              charakteru i humoru.<br/>
            </h1>
          </b-container>
        </div>
      </b-row>
      <b-row id="events">
        <EventsGrid/>
      </b-row>
      <b-row id="about-us">
        <TeamGallery :inner-width="windowWidth"/>
      </b-row>
      <b-row id="opinions">
        <OpinionsGallery :inner-width="windowWidth"/>
      </b-row>
      <b-row id="workshop">
        <WorkshopsComponent/>
      </b-row>
      <b-row id="contact">
        <ContactForm/>
      </b-row>
      <b-row id="footbar">
        <FootbarComponent/>
      </b-row>
    </div>
  </div>
</template>

<script>
import EventsGrid from "./components/EventsGrid";
import OpinionsGallery from "./components/OpinionsGallery/OpinionsGallery";
import TeamGallery from "./components/TeamGallery";
import ContactForm from "./components/ContactForm";
import WorkshopsComponent from "./components/WorkshopsComponent";
import FootbarComponent from "./components/FootbarComponent";
import MenuBar from "./components/MenuBar/MenuBar";

export default {
  name: 'App',
  components: {
    MenuBar,
    FootbarComponent,
    WorkshopsComponent,
    OpinionsGallery,
    EventsGrid,
    TeamGallery,
    ContactForm
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
}
</script>

<style>
@keyframes showUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

#landing-background {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>